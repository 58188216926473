import Vue from 'vue'
const map = new Map()

function addTooltip(el) {
    const tooltip = Vue.extend({
        template: `<el-tooltip content="${el.innerText}" placement="top">
                        <div style="overflow: hidden;text-overflow: ellipsis;white-space: nowrap">${el.innerText}</div>
                    </el-tooltip>`
    })
    const tooltipInstance = new tooltip()
    const child = tooltipInstance.$mount().$el
    el.innerHTML = ''
    el.appendChild(child)
    map.set(el, el.innerHTML)
}

function getTextWidth(text) {
    const div = document.createElement('div')
    div.style.display = 'inline-block'
    div.innerText = text
    document.body.appendChild(div)
    const width = div.clientWidth
    document.body.removeChild(div)
    return width
}

function handle(el) {
    if (getTextWidth(el.innerText) > el.clientWidth) {
        addTooltip(el)
    } else {
        const html = map.get(el)
        if (html) {
            el.innerHTML = html
            map.delete(el)
        }
    }
}

Vue.directive('tooltip', {
    inserted: function (el) {
        el.style.whiteSpace = 'nowrap'
        handle(el)
    },
    update: function (el) {
        handle(el)
    }
})
