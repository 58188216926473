import Vue from 'vue'
import Vuex from 'vuex'

import app from './modules/app'
import user from './modules/user'
import view from './modules/view'
import loginStore from './modules/login-store'
import project from './modules/project'
Vue.use(Vuex)

const store = new Vuex.Store({
    modules: {
        app,
        user,
        view,
        loginStore,
        project
    }
})
export default store
