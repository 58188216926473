export default {
    /**
     * 会话级缓存(对应sessionStorage)
     */
    sessionCache: {
        set(key, value) {
            if (!sessionStorage) {
                return
            }
            if (key && value) {
                sessionStorage.setItem(key, value)
            }
        },
        get(key) {
            if (!sessionStorage || !key) {
                return null
            }
            return sessionStorage.getItem(key)
        },
        setJSON(key, jsonValue) {
            if (jsonValue) {
                this.set(key, JSON.stringify(jsonValue))
            }
        },
        getJSON(key) {
            const value = this.get(key)
            if (value) {
                return JSON.parse(value)
            }
        },
        remove(key) {
            sessionStorage.removeItem(key)
        },
        clearAll() {
            sessionStorage.clear()
        }
    },

    /**
     * 本地缓存(对应localStorage)
     */
    localCache: {
        set(key, value) {
            if (!localStorage) {
                return
            }
            if (key && value) {
                localStorage.setItem(key, value)
            }
        },
        get(key) {
            if (!localStorage || !key) {
                return null
            }
            return localStorage.getItem(key)
        },
        setJSON(key, jsonValue) {
            if (jsonValue) {
                this.set(key, JSON.stringify(jsonValue))
            }
        },
        getJSON(key) {
            const value = this.get(key)
            if (value) {
                return JSON.parse(value)
            }
        },
        remove(key) {
            localStorage.removeItem(key)
        },
        clearAll() {
            localStorage.clear()
        }
    }
}
