import { Message } from 'element-ui'
import systemConfig from '@/config/system-config'
import securityKit from '@/security'
import router from '@/router/router'
import authorization from '@/store/utils/authorization'

/**
 * 统一响应拦截器
 */
const doInterceptor = async function (axiosResponse) {
    // 格式化响应体（仅包含响应数据data和响应头header）
    let tempAxiosResponse = { header: axiosResponse.headers, data: axiosResponse.data }
    // 二进制数据则直接返回);
    if (
        (axiosResponse.request.responseType === 'blob' || axiosResponse.request.responseType === 'arraybuffer') &&
        !axiosResponse.headers['content-type'].includes('application/json')
    ) {
        return tempAxiosResponse
    }

    if (axiosResponse.request.responseType === 'blob' || axiosResponse.request.responseType === 'arraybuffer') {
        tempAxiosResponse.data = await (() =>
            new Promise((resolve) => {
                // 二进制数据转为json
                const reader = new FileReader()
                reader.readAsText(axiosResponse.data, 'utf-8')
                reader.onload = function () {
                    resolve(JSON.parse(reader.result))
                }
            }))()
    }

    const data = tempAxiosResponse.data
    switch (String(data.status)) {
        case '201':
            break
        case '200':
            break
        case '100':
        case '1111':
            Message.warning(data.msg)
            break
        case '104':
        case '105':
        case '401':
            Message.warning('登录信息失效，已登出')
            authorization.handleLogout()
            break
        default:
            Message.warning(data.msg)
            return Promise.reject(data)
    }

    // 解密
    if (systemConfig.RES_CRYPTO_FLAG && tempAxiosResponse.header.is_back_encrypt === '1') {
        tempAxiosResponse = securityKit.axiosCrypt.axiosResDecrypt(tempAxiosResponse)
    }

    return tempAxiosResponse
}

// 响应promise的rejected处理
const resErrorHandler = function (onRejected) {
    console.log('error:', onRejected.message)
    if (onRejected.message && onRejected.message === 'DUPLICATE_REQ') {
        return Promise.reject(new Error('请勿重复提交请求！'))
    } else {
        const error = JSON.parse(JSON.stringify(onRejected))
        console.log('响应异常：', onRejected, error.message)
        if (error.config) {
            console.log('异常来自于请求路径：' + error.config.baseURL + error.config.url)
        }
        return handleResErrorCode(error)
    }
}

function handleResErrorCode(error) {
    const code = error.status
    if (code === 401 || code === 403) {
        error.message = '令牌失效'
        router.push('/login')
    } else {
        switch (code) {
            case 400:
                error.message = '错误请求'
                break
            case 404:
                error.message = '请求错误,未找到该资源'
                break
            case 405:
                error.message = '请求方法未允许'
                break
            case 408:
                error.message = '请求超时'
                break
            case 500:
                error.message = '服务器端出错'
                break
            case 501:
                error.message = '网络未实现'
                break
            case 502:
                error.message = '网络错误'
                break
            case 503:
                error.message = '服务不可用'
                break
            case 504:
                error.message = '网络超时'
                break
            case 505:
                error.message = 'http版本不支持该请求'
                break
            default:
                error.message = `连接错误${error.response.status}`
        }
        Message.error(error.message)
        return Promise.reject(error)
    }
}

export default { doInterceptor, resErrorHandler }
