import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store/store'
import abstractRoute from '@/components/abstract_route/abstract_route'
import authorization from '@/store/utils/authorization'

Vue.use(Router)

const routes = [
    {
        path: '/',
        redirect: '/zeratul'
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('@/views/login'),
        meta: {
            menuName: '登录'
        }
    },
    {
        path: '/forgetPwd',
        name: 'forgetPwd',
        component: () => import('@/views/forgetPassword'),
        meta: {
            menuName: '忘记密码'
        }
    },
    // {
    //   path: '/zeratul',
    //   component: () => import('@/views/main'),
    //   beforeEnter(to, from, next) {
    //     store.state.user.token ? next() : next('/login')
    //   },
    //   redirect: { name: 'ef20644c22c311e9872cfa163e3c5ede' },
    //   children: [
    //     {
    //       path: 'home',
    //       name: 'ef20644c22c311e9872cfa163e3c5ede',
    //       component: () => {
    //         return import('@/views/home/问卷疫情/index.vue')
    //       },
    //       meta: {
    //         menuName: '我的工时',
    //         menuCode: 'ef20644c22c311e9872cfa163e3c5ede'
    //       }
    //     },
    //     {
    //       path: 'homez',
    //       name: '07669aa822c411e9872cfa163e3c5ede',
    //       component: () => {
    //         return import('@/views/全景视图')
    //       },
    //       meta: {
    //         menuName: '全景视图',
    //         menuCode: '07669aa822c411e9872cfa163e3c5ede'
    //       }
    //     },
    //     {
    //       path: 'homex',
    //       name: 'fcbfc94e22c311e9872cfa163e3c5ede',
    //       component: () => {
    //         return import('@/views/home/homex.vue')
    //       },
    //       meta: {
    //         menuName: '项目概览',
    //         menuCode: 'fcbfc94e22c311e9872cfa163e3c5ede'
    //       }
    //     },
    //     // 项目概览
    //     {
    //       path: 'projectOverview',
    //       name: 'ade232c22c311e9872cfa163e3c5ede',
    //       component: () => {
    //         return import('@/views/项目概览/index.vue')
    //       },
    //       meta: {
    //         menuName: '项目概览',
    //         menuCode: 'ade232c22c311e9872cfa163e3c5ede'
    //       }
    //     },
    //     {
    //       path: 'gzjh',
    //       name: '554af7508e4411e9b31cfa163e3c5ede',
    //       component: () => {
    //         return import('@/views/home/计划管理.vue')
    //       },
    //       meta: {
    //         menuName: '工作计划',
    //         menuCode: '554af7508e4411e9b31cfa163e3c5ede'
    //       }
    //     },
    //     {
    //       path: 'bmgl',
    //       name: 'e204b66e22c311e9872cfa163e3c5ede',
    //       component: () => {
    //         return import('@/views/部门管理/新部门管理.vue')
    //       },
    //       meta: {
    //         menuName: '新部门管理',
    //         menuCode: 'e204b66e22c311e9872cfa163e3c5ede'
    //       }
    //     },
    //     {
    //       path: 'homepor',
    //       name: 'homepor',
    //       component: () => {
    //         return import('@/views/系统配置/项目管理/Projectview.vue')
    //       },
    //       meta: {
    //         menuName: '新部门管理',
    //         menuCode: 'homepor'
    //       }
    //     },
    //     {
    //       path: 'business',
    //       name: 'ef12345c22c311e9872cfa163e3c5ede',
    //       component: () => {
    //         return import('@/views/商机管理')
    //       },
    //       meta: {
    //         menuName: '商机管理',
    //         menuCode: 'ef12345c22c311e9872cfa163e3c5ede'
    //       }
    //     },
    //     // 项目管理
    //     {
    //       path: 'projectManagement',
    //       name: '1237daec22c311e9872cfa163e3c5ede',
    //       component: () => {
    //         return import('@/views/项目管理/index.vue')
    //       },
    //       meta: {
    //         menuCode: '1237daec22c311e9872cfa163e3c5ede',
    //         menuName: '项目管理'
    //       }
    //     },
    //     {
    //       path: 'statistics',
    //       name: 'ef12345622c311e9872cfa163e3c5ede',
    //       component: abstractRoute,
    //       redirect: { name: '404' },
    //       meta: {
    //         menuName: '统计分析',
    //         menuCode: 'ef12345622c311e9872cfa163e3c5ede'
    //       },
    //       children: [
    //         {
    //           path: 'wtbgstj',
    //           name: 'ef12345672c311e9872cfa163e3c5ede',
    //           component: () => import('@/views/统计分析/未填报工时'),
    //           meta: {
    //             menuCode: 'ef12345672c311e9872cfa163e3c5ede',
    //             menuName: '未填报工时'
    //           }
    //         },
    //         // 人员工时明细表
    //         {
    //           path: 'listOfEmployeeHours',
    //           name: 'ef12345678c311e9872cfa163e3c5ede',
    //           component: () => import('@/views/统计分析/人员工时明细表'),
    //           meta: {
    //             menuCode: 'ef12345678c311e9872cfa163e3c5ede',
    //             menuName: '人员工时明细表'
    //           }
    //         },
    //         // 计划外工作明细表
    //         {
    //           path: 'unplannedWorkList',
    //           name: 'ef123ferwerc311e9872cfa16e3c5ede',
    //           component: () => import('@/views/统计分析/计划外工作明细表'),
    //           meta: {
    //             menuCode: 'ef123ferwerc311e9872cfa16e3c5ede',
    //             menuName: '计划外工作明细表'
    //           }
    //         },
    //         // 项目工时汇总表
    //         {
    //           path: 'projectHoursList',
    //           name: 'ef123fe4349rc31e9a143fe6e13c5ede',
    //           component: () => import('@/views/统计分析/项目工时汇总表'),
    //           meta: {
    //             menuCode: 'ef123fe4349rc31e9a143fe6e13c5ede',
    //             menuName: '项目工时汇总表'
    //           }
    //         }
    //       ]
    //     },
    //     {
    //       path: 'xtpz',
    //       name: '9eaf4f12a34a49bea18f8ca9014b3112',
    //       component: abstractRoute,
    //       redirect: { name: '404' },
    //       meta: {
    //         menuCode: '9eaf4f12a34a49bea18f8ca9014b3112',
    //         menuName: '系统配置'
    //       },

    //       children: [
    //         {
    //           path: 'yqjkqktj',
    //           name: 'be8d85ec47c711eab31cfa163e3c5ede',
    //           component: () => import('@/views/系统配置/yiqing.vue'),
    //           meta: {
    //             menuCode: 'be8d85ec47c711eab31cfa163e3c5ede',
    //             menuName: '疫情健康情况统计'
    //           }
    //         },
    //         {
    //           path: 'zhhgl',
    //           name: 'a1deba9e22c311e9872cfa163e3c5ede',
    //           component: () => import('@/views/系统配置/Account-number.vue'),
    //           meta: {
    //             menuCode: 'a1deba9e22c311e9872cfa163e3c5ede',
    //             menuName: '账号管理'
    //           }
    //         },
    //         {
    //           path: 'zzhjggl',
    //           name: 'c404b7e022c311e9872cfa163e3c5ede',
    //           component: () => import('@/views/组织机构/组织机构.vue'),
    //           meta: {
    //             menuCode: 'c404b7e022c311e9872cfa163e3c5ede',
    //             menuName: '组织机构管理'
    //           }
    //         },
    //         {
    //           path: 'jsgl',
    //           name: 'bc12be4c4abe11e9b31cfa163e3c5ede',
    //           component: () => import('@/views/系统配置/role/roleIndex.vue'),
    //           meta: {
    //             menuCode: 'bc12be4c4abe11e9b31cfa163e3c5ede',
    //             menuName: '角色管理'
    //           }
    //         },
    //         {
    //           path: 'xmgl',
    //           name: 'd2b7daec22c311e9872cfa163e3c5ede',
    //           component: () => import('@/views/系统配置/项目管理/index.vue'),
    //           meta: {
    //             menuCode: 'd2b7daec22c311e9872cfa163e3c5ede',
    //             menuName: '项目管理'
    //           }
    //         },
    //         {
    //           path: 'wenjuangl',
    //           name: '8e0a79325df411eab31cfa163e3c5ede',
    //           component: () => import('@/views/系统配置/问卷管理/index.vue'),
    //           meta: {
    //             menuCode: '8e0a79325df411eab31cfa163e3c5ede',
    //             menuName: '问卷管理'
    //           }
    //         },
    //         {
    //           path: 'xzbb',
    //           name: '24c13d4c51d111e9b31cfa163e3c5ede',
    //           component: () => import('@/views/系统配置/downloadBaobiao.vue'),
    //           meta: {
    //             menuCode: '24c13d4c51d111e9b31cfa163e3c5ede',
    //             menuName: '下载报表'
    //           }
    //         },
    //         {
    //           path: 'number',
    //           name: '1',
    //           component: () => import('@/views/系统配置/number/number-configuration'),
    //           meta: {
    //             menuCode: '1',
    //             menuName: '编码配置'
    //           }
    //         },
    //         {
    //           path: 'number/generate',
    //           name: '2',
    //           component: () => import('@/views/系统配置/number/generate-number'),
    //           meta: {
    //             menuCode: '2',
    //             menuName: '编码生成'
    //           }
    //         },
    //         {
    //           path: 'Holidays',
    //           name: 'bcdeba9e22c311e9872cfa163e3c5ede',
    //           component: () => import('@/views/系统配置/节假日配置'),
    //           meta: {
    //             menuCode: 'bcdeba9e22c311e9872cfa163e3c5ede',
    //             menuName: '节假日配置'
    //           }
    //         },
    //         {
    //           path: 'TaskName',
    //           name: 'fsdsf3232208e441872cfa163e3c5ede',
    //           component: () => import('@/views/系统配置/任务名称配置'),
    //           meta: {
    //             menuCode: 'fsdsf3232208e441872cfa163e3c5ede',
    //             menuName: '任务名称配置'
    //           }
    //         }
    //       ]
    //     },
    //     {
    //       path: 'market',
    //       name: '123456789',
    //       component: abstractRoute,
    //       redirect: { name: '404' },
    //       meta: {
    //         menuName: '市场合作',
    //         menuCode: '123456789'
    //       },
    //       children: [
    //         {
    //           path: 'toubiao',
    //           name: '12345',
    //           component: () => import('@/views/市场合作/投标管理'),
    //           meta: {
    //             menuCode: '12345',
    //             menuName: '投标管理'
    //           }
    //         }
    //       ]
    //     }
    //   ]
    // },
    {
        path: '/404',
        name: '404',
        meta: {
            menuName: '404-页面不存在'
        },
        component: () => import('@/views/error/404.vue')
    }
    // {
    //   path: '*',
    //   redirect: '/404'
    // }
]

const router = new Router({
    mode: 'history',
    routes
})

// router.beforeEach((to, from, next) => {
//   if (to.path === '/' || to.path === '/login' || to.path === '/zeratul/home' || to.path === '/forgetPwd' || to.path === '/404') {
//     return next()
//   }
//   handleRoutes(routes)
//   const menuList = JSON.parse(sessionStorage.getItem('menuList'))
//   const menuTable = []
//   handleMenu(menuList, menuTable)
//   for (const menu of menuTable) {
//     if (routeTable[menu] === to.path) {
//       return next()
//     }
//   }
//   return next('/404')
// })

router.beforeEach((to, then, next) => {
    // 页面跳转，中断所有未完成的请求
    // 放行白名单
    if (to.name === 'login') {
        return next()
    }
    // 过滤非法请求
    if (authorization.getToken()) {
        // 拦截损坏的用户信息
        if (!store.state.user.userInfo) {
            console.log('账户信息异常')
            // 清空缓存，重新登录
            authorization.clearAllCache()
            return next('/login')
        }

        // 拦截损坏的路由
        if (!authorization.getUserRouter()) {
            console.error('路由缓存损坏')
            // 清空缓存，重新登录
            authorization.clearAllCache()
            return next('/login')
        }
        // 路由追加
        if (store.getters.getFlushFlag) {
            // 动态添加路由
            authorization
                .initializeUserRouter(authorization.getUserRouter())
                .then(() => {
                    store.commit('setFlushFlag', false)
                    return next({ ...to, replace: true })
                })
                .catch((err) => {
                    console.log('渲染路由失败：', err)
                    // 清空缓存，重新登录
                    authorization.clearAllCache()
                    // return next('/login')
                })
            // 加载完成菜单信息，执行跳转
            return next()
        }
        return next()
    } else {
        console.log('身份凭证不存在或已损坏')
        // 清空缓存
        authorization.clearAllCache()
        return next('/login')
    }
})

// 处理可以访问的菜单的 id
function handleMenu(menuList, menuTable) {
    for (const menu of menuList) {
        menuTable.push(menu.id)
        menu.children && handleMenu(menu.children, menuTable)
    }
}

let routeTable = {}
// 处理路由
function handleRoutes(rou, parPath) {
    rou &&
        rou.forEach((item) => {
            const path = parPath ? parPath + '/' + item.path : item.path
            routeTable[item.name] = path
            item.children && handleRoutes(item.children, path)
        })
}

export default router
