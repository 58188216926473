import axios from 'axios'
import utilKit from '@/util'

const baseURL = process.env.VUE_APP_BASE_API

const author = axios.create({
    baseURL,
    withCredentials: true
})
author.interceptors.request.use(
    (config) => {
        // Do something before request

        if (!config.data) {
            config.data = {}
        }
        return utilKit.unifiedReqInterceptor.doInterceptor(config)
    },
    (error) => {
        return Promise.reject(error)
    }
)
author.interceptors.response.use(
    (response) => {
        // Do something before response
        return utilKit.unifiedResInterceptor.doInterceptor(response)
    },
    (error) => {
        throw error
    }
)
author.downTempLate = function () {
    window.open(`${baseURL}/api/work-hour-portal/loginCtrl/fileDownload`)
}
author.downTempLate1 = function (e) {
    window.open(`${baseURL}${e}`)
}
author.downTempLateExcel = function () {
    window.open(`${baseURL}/api/work-hour-portal/manager/exportCallNumToExecl`)
}
//计划管理已完成导出
author.DownTempLateposy = function () {
    window.open(`${baseURL}/api/work-hour-portal/planController/exportFinishPlan`)
}
//计划管理延期导出
author.PownTempLatespoi = function () {
    window.open(`${baseURL}/api/work-hour-portal/planController/exportUnfinishPlan`)
}
//6.14新增下载报表
// author.downTempLate2 = function(e) {
//   window.open(`${baseURL}${e}`);
// };
// author.downTempLate3 = function(e) {
//   window.open(`${baseURL}${e}`);
// };
// author.downTempLate4 = function(e) {
//   window.open(`${baseURL}${e}`);
// };

const unauth = axios.create({
    baseURL,
    withCredentials: true
})

unauth.interceptors.request.use(
    (config) => {
        return config
    },
    (error) => {
        return Promise.reject(error)
    }
)
unauth.interceptors.response.use(
    (response) => {
        return response
    },
    (error) => {
        return Promise.reject(error)
    }
)

export { author, unauth, baseURL }
