var user = {
    state: {
        token: window.localStorage.getItem('AUTH_TOKEN') || '',
        projectId: window.localStorage.getItem('projectId') || '',
        projectName: window.localStorage.getItem('projectName') || '',
        projectList: JSON.parse(window.localStorage.getItem('projectList')) || [],
        menuList: [],
        userInfo: JSON.parse(window.localStorage.getItem('userInfo')) || {},
        project: window.localStorage.getItem('project') || '',
        userName: window.localStorage.getItem('userName') || '',
        newFeatureTips: window.localStorage.getItem('newFeatureTips') || ''
    },
    mutations: {
        login(state, payload) {
            state.token = payload.token
            // state.projectList = payload.projectList;
            // payload.projectList.forEach(item => {
            //   if (item.isDefault === "1") {
            //     state.projectId = item.projectId;
            //     window.localStorage.setItem("projectId", item.projectId);
            //     window.localStorage.setItem("projectName", item.projectName);
            //     state.projectName = item.projectName;
            //     return;
            //   }
            // });
            window.localStorage.setItem('token', payload.token)
            // window.localStorage.setItem(
            //   "projectList",
            //   JSON.stringify(payload.projectList)
            // );
        },
        setUser(state, payload) {
            state.userInfo = payload
            window.localStorage.setItem('userInfo', JSON.stringify(payload))
        },
        setMenuList(state, payload) {
            state.menuList = payload.menuList
        },
        setProject(state, payload) {
            state.project = payload.project
            window.localStorage.setItem('project', payload.project)
        },
        setUserName(state, payload) {
            state.userName = payload.userName
            window.localStorage.setItem('userName', payload.userName)
        },
        logout(state, payload) {
            window.localStorage.clear()
            state.token = ''
            state.projectId = ''
            state.projectName = ''
            //state.projectList = [];
            state.menuList = []
        },
        setNewFeatureTips(state, data) {
            state.newFeatureTips = JSON.stringify(data)
            window.localStorage.setItem('newFeatureTips', JSON.stringify(data))
        }
    },
    getters: {
        getProjectId(state) {
            return state.projectId
        },
        getProjectName(state) {
            return state.projectName
        },
        // getProjectList(state) {
        //   return state.projectList;
        // },
        getMenuList(state) {
            return state.menuList
        },
        getUserName(state) {
            return state.userInfo.userName
        },
        getUserInfo(state) {
            return state.userInfo
        },
        getProject(state) {
            return state.project
        },
        getNewFeatureTips(state) {
            return state.newFeatureTips
        }
    }
}
export default user
