/**
 * 统一鉴权处理（动态路由生成，登录、登出善后处理）
 */
import clientCache from '@/store/utils/client-cache'
import router from '@/router/router'
import store from '@/store/store'

function loadView(view) {
    if (process.env.NODE_ENV === 'development') {
        return (resolve) => require([`@/${view}`], resolve)
    } else {
        // 使用 import 实现生产环境的路由懒加载
        return () => import(`@/${view}`)
    }
}

function filterAsyncRouter(resRouter) {
    return resRouter.filter((route) => {
        route.component = loadView(route.component)
        if (route.id) {
            route.meta.menuId = route.id
        }
        if (route.children != null && route.children && route.children.length) {
            route.children = filterAsyncRouter(route.children)
        }
        return true
    })
}

function filterHiddenMenu(menuList) {
    return menuList.filter((item) => {
        if (item.children && item.children.length) {
            item.children = filterHiddenMenu(item.children)
        }
        return !item.hidden
    })
}

const authorization = {
    // 处理登录
    handleLogin(data) {
        console.log('data', data)
        // 存储Token
        if (data.authToken) {
            store.commit('SET_TOKEN', data.authToken)
        }
        // 存储用户信息
        if (data.userInfo) {
            const d = data.userInfo
            const userInfo = {
                id: d.userId,
                userName: d.username,
                // userPwd: '5a63d7ff614b2970580e566e1b6efd15',
                staffName: d.nickname,
                staffId: d.userCode,
                // state: null,
                genre: d.userType,
                organId: d.deptId,
                positionId: d.positionId,
                phone: d.phone,
                email: d.email,
                isActive: true,
                permissions: d.permissions,
                roles: d.roles
                // createBy: '3750427fccf547ebbcb5c7f198d8914a',
                // createDate: 1656999347000,
                // modifyBy: null,
                // modifyDate: null,
                // isCharge: '0',
                // roleId: '318a9ce72d6111eb97eafa202000db84',
                // nowDate: '2023-03-15'
            }
            store.commit('setUser', userInfo)
        }
        // 存储用户路由
        if (data.userRouter) {
            store.commit('SET_USER_ROUTER', data.userRouter)
        }
        // 存储用户菜单
        if (data.userRouter && data.userRouter.length > 0) {
            let menuList = authorization.filterMenuList(data.userRouter)
            menuList = filterHiddenMenu(menuList)
            if (menuList.length > 0) {
                data.menuList = menuList
                store.commit('SET_USER_MENU', data.menuList)
                // 存储默认标签栏
                // store.commit('SET_DEFAULT_TAG', data.menuList[0])
                // 初始化标签栏数据
                // store.commit('SET_TAGS', [
                //   {
                //     path: data.menuList[0].path,
                //     label: data.menuList[0].menuName
                //   }
                // ])
            }
        }
    },

    // 登出
    handleLogout() {
        store.commit('SET_TOKEN', '')
        store.commit('setUser', '')
        store.commit('SET_USER_ROUTER', '')
        console.log('登出')
        // 页面跳转
        authorization.clearAllCache()
    },

    // 更新token
    setToken(tokenStr) {
        if (tokenStr) {
            clientCache.localCache.set('AUTH_TOKEN', tokenStr)
        }
    },

    // 获取token
    getToken() {
        return clientCache.localCache.get('AUTH_TOKEN')
    },

    // 清空所有本地存储
    clearAllCache() {
        clientCache.localCache.clearAll()
        clientCache.sessionCache.clearAll()
    },

    // 初始化用户路由(入参是后端返回的原始路由)
    initializeUserRouter(responseRouter) {
        // 本工程所有后台管理页面均在App.vue下的Home.vue组件中显示
        const rootRouter = {
            path: '/zeratul',
            name: 'zeratul',
            component: 'views/main',
            meta: {
                menuName: '我的工时',
                menuCode: 'zeratul'
            },
            redirect: '/zeratul/home',
            children: []
        }

        return new Promise((resolve) => {
            // debugger
            rootRouter.children = JSON.parse(JSON.stringify(responseRouter))
            const rdata = filterAsyncRouter([rootRouter])
            console.log('rdata', rdata)
            rdata.push({ path: '*', redirect: '/404' })
            for (let i = 0, length = rdata.length; i < length; i += 1) {
                const element = rdata[i]
                router.addRoute(element)
            }
            store.commit('SET_USER_ROUTER', responseRouter)
            resolve(rdata)
        })
    },

    // 计算用户菜单
    filterMenuList(Menu) {
        console.log('原始菜单：', Menu)
        const originMenu = JSON.parse(JSON.stringify(Menu))
        return authorization.madeMenu(originMenu)
    },

    madeMenu(originMenu) {
        return originMenu.filter((omenu) => {
            if (omenu.menuPath) {
                omenu.path = '/zeratul' + omenu.menuPath
            } else {
                omenu.path = '/zeratul' + omenu.path
            }
            omenu.menuName = omenu.meta.menuName
            omenu.iconClass = omenu.meta.icon
            if (omenu.children != null && omenu.children && omenu.children.length) {
                omenu.children = authorization.madeMenu(omenu.children)
            }
            return true
        })
    },

    // 更新用户路由
    setUserRouter(resRouter) {
        if (resRouter) {
            clientCache.sessionCache.setJSON('CLIENT_USER_ROUTER', resRouter)
        }
    },

    // 获取用户路由
    getUserRouter() {
        return clientCache.sessionCache.getJSON('CLIENT_USER_ROUTER')
    },

    // 更新用户菜单信息
    setUserMenu(userMenu) {
        clientCache.sessionCache.setJSON('CLIENT_USER_MENU', userMenu)
    },

    // 获取用户菜单信息
    getUserMenu() {
        return clientCache.sessionCache.getJSON('CLIENT_USER_MENU')
    },

    // 更新用户信息
    setUserInfo(userInfo) {
        if (userInfo) {
            clientCache.localCache.setJSON('userInfo', userInfo)
        }
    },

    // 获取用户信息
    getUserInfo() {
        return clientCache.localCache.getJSON('userInfo')
    }
}
export default authorization
