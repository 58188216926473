import qs from 'qs'

// 格式化post请求参数
function postReqFormat(axiosRequestConfig) {
    if (axiosRequestConfig.method === 'post') {
        axiosRequestConfig.headers['Content-type'] = 'application/json;charset=UTF-8'
        axiosRequestConfig.data = JSON.stringify(axiosRequestConfig.data)
    }
    return axiosRequestConfig
}

// 格式化get请求参数
function getReqFormat(axiosRequestConfig) {
    if (axiosRequestConfig.method === 'get') {
        axiosRequestConfig.paramsSerializer = function () {
            return qs.stringify(axiosRequestConfig.params, { arrayFormat: 'repeat' })
        }
    }
    return axiosRequestConfig
}

// 格式化请求参数
function reqFormat(axiosRequestConfig) {
    if (axiosRequestConfig) {
        return getReqFormat(axiosRequestConfig) || postReqFormat(axiosRequestConfig)
    } else {
        return axiosRequestConfig
    }
}

export default { reqFormat }
