import Vue from 'vue'
import authorization from '@/store/utils/authorization'

Vue.directive('permit', {
    inserted: function (el, binding) {
        if (!authorization.getUserInfo().permissions.includes(binding.value)) {
            el.parentNode.removeChild(el)
        }
    }
})
