<template>
    <div id="app">
        <router-view ref="" />
        <v-tour mask-class="mask" name="myTour" :steps="steps" :options="myOptions" :callbacks="myCallbacks">
            <!-- <template slot-scope="tour">
  <transition name="fade">
    <v-step v-if="tour.steps[tour.currentStep]" :key="tour.currentStep" :step="tour.steps[tour.currentStep]" :previous-step="tour.previousStep" :next-step="tour.nextStep" :stop="tour.stop" :skip="tour.skip" :is-first="tour.isFirst" :is-last="tour.isLast" :labels="tour.labels">
      <template v-if="tour.currentStep === 1">
        <div slot="actions">
          <button @click="tour.previousStep" class="btn btn-primary">Previous step</button>
          <button @click="tour.nextStep" class="btn btn-primary">Next step</button>
        </div>
      </template>
    </v-step>
  </transition>
</template> -->
        </v-tour>
        <canvas id="canvas" v-if="showMask"></canvas>
    </div>
</template>
<script>
export default {
    data() {
        return {
            showMask: false,
            myOptions: {
                useKeyboardNavigation: false, // 是否通过键盘的←, → 和 ESC 控制指引
                labels: {
                    // 指引项的按钮文案
                    buttonSkip: '跳过指引', // 跳过文案
                    buttonPrevious: '上一步', // 上一步文案
                    buttonNext: '下一步', // 下一步文案
                    buttonStop: '结束' // 结束文案
                },
                highlight: false // 是否高亮显示激活的的target项
            },
            steps: [],
            myCallbacks: {
                onStart: this.onStart, // 开始时触发
                onPreviousStep: this.onPreviousStep, // 上一步时触发
                onNextStep: this.onNextStep, // 下一步时触发
                onSkip: this.onSkip, // 跳过时触发
                onFinish: this.onFinish // 结束时触发
            },
            index: -1
        }
    },
    watch: {
        $route(val) {
            this.steps = []
            if (!this.$store.getters.getNewFeatureTips) return
            const data = JSON.parse(this.$store.getters.getNewFeatureTips)
            this.index = data?.findIndex((v) => v.menuId === val.meta.menuCode)

            if (this.index !== -1 && this.index !== undefined) {
                const stepData = data[this.index]
                JSON.parse(stepData.step).map((i) => {
                    this.steps.push({
                        target: i.id, // 当前项的id或class或data-v-step属性
                        content: i.content, // 当前项指引内容
                        params: {
                            placement: i.placement || 'bottom', // 指引在target的位置，支持上、下、左、右
                            highlight: false, // 当前项激活时是否高亮显示
                            enableScrolling: true // 指引到当前项时是否滚动轴滚动到改项位置
                        },
                        before: new Function(i.before)
                    })
                })
            }
        }
    },
    provide() {
        return {
            app: this
        }
    },
    mounted() {},
    methods: {
        clearRect(id) {
            if (!id) return
            this.showMask = true
            this.$nextTick(() => {
                const canvas = document.querySelector('#canvas')
                canvas.width = document.documentElement.clientWidth
                canvas.height = document.documentElement.clientHeight
                const context = canvas.getContext('2d')
                context.fillStyle = 'rgba(0,0,0,0.5)' //设置填充色（可以是渐变色或半透明色）
                context.fillRect(0, 0, canvas.width, canvas.height) //填充背景我色
                const target = document.querySelector(id)
                if (!target) {
                    this.showMask = false
                    return
                }
                const position = target.getBoundingClientRect()
                context.clearRect(position.x, position.y, position.width, position.height) //抠出一个矩形区域
            })
        },
        // 开始时触发
        onStart() {
            this.clearRect(this.steps[0].target)
        },
        // 上一步时触发
        onPreviousStep(val) {
            this.clearRect(this.steps[val - 1].target)
        },
        // 下一步时触发
        onNextStep(val) {
            this.clearRect(this.steps[val + 1].target)
        },
        // 跳过时触发
        onSkip() {
            this.showMask = false
            const data = JSON.parse(this.$store.getters.getNewFeatureTips)
            this.$http.author
                .post('/api/work-hour-portal/guideRecord/add', { guideId: data[this.index].id })
                .then(({ data }) => {
                    if (data.status === 200) {
                        return this.$http.author.get('/api/work-hour-portal/guide/getByGuide')
                    }
                })
                .then(({ data }) => {
                    if (data.status === 200) {
                        this.$store.commit('setNewFeatureTips', data.data)
                        this.steps = []
                    }
                })
        },
        // 结束时触发
        onFinish() {
            this.showMask = false
            const data = JSON.parse(this.$store.getters.getNewFeatureTips)
            this.$http.author
                .post('/api/work-hour-portal/guideRecord/add', { guideId: data[this.index].id })
                .then(({ data }) => {
                    if (data.status === 200) {
                        return this.$http.author.get('/api/work-hour-portal/guide/getByGuide')
                    }
                })
                .then(({ data }) => {
                    if (data.status === 200) {
                        this.$store.commit('setNewFeatureTips', data.data)
                        this.steps = []
                    }
                })
        }
    }
}
</script>

<style lang="scss">
html,
body,
#app {
    width: 100%;
    height: 100%;
    overflow: hidden;
    font-size: 14px;
    font-family: 'Helvetica Neue', Helvetica, 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', '微软雅黑', Arial, sans-serif;
}

#app {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

#canvas {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 5000;
}
</style>
<style lang="scss" scoped>
::v-deep .v-step {
    background: #fff !important;
    color: #000 !important;
}

::v-deep .v-step__buttons .v-step__button {
    color: #fff !important;
    border-color: dodgerblue !important;
    background: dodgerblue !important;
}
</style>
