/**
 * 登录模块的缓存处理
 */
import authorization from '@/store/utils/authorization'
const loginStore = {
    state: {
        // 登录者信息
        userInfo: '',
        // 登录者路由
        userRouter: [],
        // 登录者菜单信息
        userMenuList: [],
        // 用户角色
        roles: [],
        // 用户权限
        permissions: [],
        token: '',
        // 刷新标识，此值为true时，需要从sessionStorage中获取路由信息，计算得到动态路由。在路由守卫中使用。
        flushFlag: true
    },
    mutations: {
        SET_TOKEN: (state, token) => {
            state.token = token
            authorization.setToken(token)
        },
        SET_USERINFO: (state, userInfo) => {
            state.userInfo = userInfo
            authorization.setUserInfo(userInfo)
        },
        SET_USER_ROUTER: (state, userRouter) => {
            state.userRouter = userRouter
            authorization.setUserRouter(userRouter)
        },
        SET_USER_MENU: (state, userMenu) => {
            state.userMenuList = userMenu
            authorization.setUserMenu(userMenu)
        },
        setFlushFlag: (state, flag) => {
            state.flushFlag = flag
        }
    },
    getters: {
        getFlushFlag(state) {
            return state.flushFlag
        },
        // getUserInfo(state) {
        //   return state.userInfo || authorization.getUserInfo()
        // },
        getUserRouter(state) {
            return JSON.stringify(state.userRouter) === '[]' ? authorization.getUserRouter() : state.userRouter
        },
        getUserMenuList(state) {
            return JSON.stringify(state.userMenuList === '[]') ? authorization.getUserMenu() : state.userMenuList
        },
        getToken(state) {
            return state.token || authorization.getToken()
        }
    }
}
export default loginStore
