export default {
    state: {
        // 项目分析的数据
        projectAnalysisData: {},
        // 商机分析数据
        businessAnalysisData: {},
        // 人员分析数据
        personAnalysisData: {}
    },
    mutations: {
        // 修改项目分析的数据
        setProjectAnalysisData(state, payload) {
            state.projectAnalysisData = payload
        },
        // 修改商机分析数据
        setBusinessAnalysisData(state, payload) {
            state.businessAnalysisData = payload
        },
        // 修改人员分析数据
        setPersonAnalysisData(state, payload) {
            state.personAnalysisData = payload
        }
    },
    getters: {
        // 获取项目分析的数据
        getProjectAnalysisData(state) {
            return state.projectAnalysisData
        },
        // 获取商机分析数据
        getBusinessAnalysisData(state) {
            return state.businessAnalysisData
        },
        // 获取人员分析数据
        getPersonAnalysisData(state) {
            return state.personAnalysisData
        }
    }
}
