/**
 * 系统配置文件
 */
export default {
    /**
     * 安全配置
     */
    // 统一请求拦截器是否执行请求加密
    REQ_CRYPTO_FLAG: true,
    // 统一响应拦截器是否执行响应解密
    RES_CRYPTO_FLAG: true,
    // 请求加密
    REQ_PUBLIC: '043CAF0036B7559D4993E2C25EF72C6A84623D7B287E5714E41433CE2840C682A0AAB7545B1A69530DC1C8DB884BD245ED999C2BE448657D1FA78E044E697541BD',
    // 响应解密
    RES_PRIVATE: '1141441A833AE2BFE02EA1187E3E6D0509A5EB643394C2434D072AE247003EBB',
    // 防会话重放攻击固定盐值
    REPLAY_ATTACKS_SALT: '04NO39Z15O85C51O2DI1'
}
