import utilKit from '@/util'
import securityKit from '@/security'
import router from '@/router/router'
import store from '@/store/store'
import systemConfig from '@/config/system-config'
import { Message } from 'element-ui'
import authorization from '@/store/utils/authorization'

/**
 * 统一请求拦截器
 */
const doInterceptor = function (axiosRequestConfig) {
    let config = axiosRequestConfig
    // 请求参数序列化
    config = utilKit.reqFormatTool.reqFormat(axiosRequestConfig)

    // 加密
    if (systemConfig.REQ_CRYPTO_FLAG) {
        // config.headers.is_front_encrypt = '1'
        if (config.headers.is_front_encrypt === '1') {
            config = securityKit.axiosCrypt.axiosReqEncrypt(axiosRequestConfig, 'standard')
        }
    }

    const token = authorization.getToken()
    if (!token && router.currentRoute.path !== '/login') {
        router.replace('/login')
        Message.error('未登录！')
        return Promise.reject('unauthorization')
    }
    config.headers['X-Token'] = `${token}`

    return config
}

export default { doInterceptor }
