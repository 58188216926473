export default {
    namespaced: true,

    state: {
        key: ''
    },
    getters: {
        key: (state) => state.key
    },
    mutations: {
        SET_KEY: (state, key) => {
            state.key = key
        }
    }
}
