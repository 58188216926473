/**
 * sm2 加解密组件（用于axios加解密）
 */
import { sm2 } from 'sm-crypto'
import systemConfig from '@/config/system-config'
import utilKit from '@/util'

const REQ_PUBLIC = systemConfig.REQ_PUBLIC
const RES_PRIVATE = systemConfig.RES_PRIVATE
const cipherMode = 1 // 1 - C1C3C2，0 - C1C2C3，默认为1

// 基础加密
const encrypto = function (str) {
    return '04' + sm2.doEncrypt(str, REQ_PUBLIC, cipherMode)
}
// 基础解密
const decrypto = function (str) {
    return sm2.doDecrypt(str.substring(2), RES_PRIVATE, cipherMode)
}

// 完全加密(返回加密后的密文字符串)
const completeEncrypt = function (toEncrypt) {
    if (utilKit.checkDataType.getType(toEncrypt) !== 'string') {
        throw new Error(
            "无法对'" + utilKit.checkDataType.getType(toEncrypt) + "'进行加密。Cannot encrypt '" + utilKit.checkDataType.getType(toEncrypt) + "'."
        )
    } else {
        return encrypto(toEncrypt)
    }
}
// 完全解密(返解密后的json字符串)
const completeDecrypt = function (toDecrypt) {
    if (utilKit.checkDataType.getType(toDecrypt) === 'string') {
        return decrypto(toDecrypt)
    } else {
        console.log('服务器响应：', toDecrypt)
        throw new Error(
            "使用完全解密时，解密的入参必须是String类型。Expected input parameter is of type 'String', but got a '" +
                utilKit.checkDataType.getType(toDecrypt) +
                "'."
        )
    }
}

// 标准加密(入参是对象，返回加密后的JSON对象)
const standardEncrypt = function (toEncrypt) {
    if (utilKit.checkDataType.getType(toEncrypt) === 'Object') {
        for (const key in toEncrypt) {
            if (utilKit.checkDataType.getType(toEncrypt[key]) === 'Object') {
                toEncrypt[key] = encrypto(JSON.stringify(toEncrypt[key]))
            } else if (utilKit.checkDataType.getType(toEncrypt[key]) === 'Array') {
                toEncrypt[key] = encrypto(JSON.stringify(toEncrypt[key]))
            } else if (utilKit.checkDataType.getType(toEncrypt[key]) === 'number') {
                toEncrypt[key] = encrypto(toEncrypt[key] + '')
            } else {
                if (toEncrypt[key]) {
                    toEncrypt[key] = encrypto(toEncrypt[key])
                }
            }
        }
        return toEncrypt
    } else {
        throw new Error("使用标准加密时，入参必须是一个对象。In 'standardEncrypt (toEncrypt)', the input parameter must be an object.")
    }
}
// 标准解密(入参是加密后的JSON对象，返回解密后的JSON对象)
const standardDecrypt = function (toDecrypt) {
    if (utilKit.checkDataType.getType(toDecrypt) === 'Object') {
        try {
            const toDecryptObj = {}
            for (const key in toDecrypt) {
                let value
                if (key === 'data' && toDecrypt[key]) {
                    value = decrypto(toDecrypt[key])
                } else {
                    value = toDecrypt[key]
                }
                toDecryptObj[key] = value
            }
            if (toDecryptObj.data) {
                toDecryptObj.data = JSON.parse(toDecryptObj.data)
            }
            return toDecryptObj
        } catch (e) {
            console.log(e)
            console.log('服务器响应：', toDecrypt)
            throw new Error('数据损坏，解密失败！')
        }
    } else {
        throw new Error(
            "使用标准解密时，解密的入参必须是Object类型。Expected input parameter is 'Object', but got a '" +
                utilKit.checkDataType.getType(toDecrypt) +
                "'."
        )
    }
}

export default { completeEncrypt, completeDecrypt, standardEncrypt, standardDecrypt }
