import Vue from 'vue'
import App from './App.vue'
import router from './router/router'
import store from './store/store'

// vuescroll
import vuescroll from 'vuescroll'
import 'vuescroll/dist/vuescroll.css'

Vue.use(vuescroll)

import VueTour from 'vue-tour'

require('vue-tour/dist/vue-tour.css')

Vue.use(VueTour)

import treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'

Vue.component('treeselect', {
    extends: treeselect,
    // 绑定select事件
    mounted() {
        this.$on('select', () => {
            // 清除页面选中
            setTimeout(() => {
                window.getSelection().removeAllRanges()
            })
        })
    }
})

// elementui component
import ElementUI from 'element-ui'
import './assets/css/element-variables.scss'

Vue.use(ElementUI, { size: 'small' })

// main.js
import ElTable from '@/components/elTable'
Vue.use(ElTable)

// http service
import { author, unauth } from '@/service/service'
import axios from 'axios'

Vue.prototype.$http = axios
Vue.prototype.$http.author = author
Vue.prototype.$http.unauth = unauth

import util from '@/libs/util'

Vue.prototype.$util = util
// css
import './assets/css/reset.css'
import './assets/css/font.less'
import './assets/css/them.css'

// 自定义菜单拽指令
import './directive/menuDrag'

// 自定义权限指令
import './directive/permit'

import './directive/tooltip'

Vue.config.productionTip = false

new Vue({
    router,
    store,
    render: (h) => h(App)
}).$mount('#app')
