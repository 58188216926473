// 应用用store
var app = {
    state: {
        menuActiveIndex: sessionStorage.getItem('activeIndex')
    },
    mutations: {
        // 修改人员分析数据
        setMenuActiveIndex(state, payload) {
            state.menuActiveIndex = payload
        }
    },
    getters: {
        // 获取项目分析的数据
        getMenuActiveIndex(state) {
            return state.menuActiveIndex
        }
    }
}
export default app
